<template>
  <div class="download">
    <div class="banner">
      <img src="../assets/download_img_bg380.png" alt="">
      <div class="down">
        <div @click="downLoad">立即下载</div>
      </div>
    </div>
    <img class="des" src="../assets/download_img_particulars.png" alt="">
    <div class="wx" v-if="showwx">
      <img src="../assets/wx.png" alt="">
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        showwx:false
      }
    },
    mounted() {
      _hmt.push(['_trackPageview', '/download'])

    },
    methods: {
      downLoad() {
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isAndroid) {
          window.location = "http://dl.ilvdo.com/download/akh";
        } else {
          if(this.isWeiXin()){
            this.showwx=true
          }else{
            window.location.href = "https://itunes.apple.com/cn/app/id992714103?mt=8";
          }
        }
      },
      isWeiXin() {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
          return true;
        } else {
          return false;
        }
      },
    }
  }

</script>

<style scoped lang="scss">
  .banner {
    position: relative;

    img {
      width: 100%;
    }

    .down {
      position: absolute;
      left: 0;
      top: 230px;
      width: 100%;

      div {
        margin: auto;
        width: 320px;
        height: 80px;
        background: #FFFFFF;
        border-radius: 40px;
        text-align: center;
        line-height: 82px;
        font-size: 30px;
        font-weight: bold;
        color: #21D0C8;
      }
    }
  }
  .wx{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.8);
    text-align: right;
    img{
      width: 600px;
    }
  }
  .des {
    width: 100%;
  }

</style>
<style>


</style>
